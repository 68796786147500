import { Paper } from "@mantine/core"
import { OPL_full } from "src/features/OPL/types"
import { SearchingList } from "./SearchingList"
import { useTranslation } from "react-i18next"
import { NotificationsPage } from "src/features/UserNotifications/components/NotificationsPage"
import { useOPLnotifications } from "src/features/UserNotifications/api/getOPLnotifications"

type CurrentOPLProps = {
    tab?: string,
    OPL: OPL_full,
    id: number | null,
    setId: (x: number | null) => void,
}

export const CurrentOPL: React.FC<CurrentOPLProps> = ({tab, OPL, id, setId}) => {

    const { t } = useTranslation()

    const { data: notifications } = useOPLnotifications(OPL.Id)

    return(
        <Paper className="mb-4" p="md" shadow="sm" style={{overflowY: "scroll", overflowX: "hidden", marginBottom: 0, boxSizing: "border-box", flexGrow: 1}}>
            {tab === "list" ?
                <SearchingList
                    elements={OPL.OverheadPowerLineTowers}
                    label={t("Опоры")}
                    setId={setId}
                    id={id}
                    styles={{width: "100%", height: "fit-content", padding: 0}}
                />
            :
                <NotificationsPage
                    notifications = {notifications ? notifications : []}
                />
            }
        </Paper>
    )
}