import {
    Avatar,
    Divider,
    Group,
    Menu,
    Navbar,
    UnstyledButton,
    Transition,
    Stack,
    Text,
    ThemeIcon,
    Drawer,
    Button,
} from "@mantine/core";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import {
    IconDeviceDesktopAnalytics,
    IconReportAnalytics,
    IconUser,
    IconLogout,
    IconChevronsLeft,
    IconChevronsRight,
    IconPlus,
    IconAlien,
    IconAccessPoint,
    IconInfoCircle,
    IconWorld,
    IconBriefcase,
    IconMap2,
    IconHeartRateMonitor,
    IconSettings,
    IconGraph,
} from "@tabler/icons-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuth } from "src/lib/auth";
import { MainLink, MainLinkProps } from "../Navbar/MainLink";
// import Logo from "./Logo.png";
// import LogoSmall from "./LogoSmall.png";
import { Logo } from "../EnergyLogo";
import { CreateDeviceDrawer } from "src/features/devices/components/CreateDeviceDrawer";
import { RoleEnum } from "src/features/auth";
import { ChangeLanguageDrawer } from "src/features/devices/components/ChangeLanguageDrawer";
import { NotificationsLayout } from "./NotificationsLayout";

const userLinks: MainLinkProps[] = [
    {
        label: "Карта",
        icon: <IconMap2 size={20} />,
        to: "/map",
    },
    {
        label: "Мониторинг",
        icon: <IconHeartRateMonitor size={20} />,
        to: "/monitoring",
    },
    {
        label: "Устройства",
        icon: <IconDeviceDesktopAnalytics size={20} />,
        to: "/devices",
    },
    {
        label: "Работа с графикой",
        icon: <IconGraph size={20} />,
        to: "/graphic",
    },
    {
        label: "Отчеты",
        icon: <IconReportAnalytics size={20} />,
        to: "/reports",
    },
    /*{
        label: "IEC",
        icon: <IconReportSearch size={20} />,
        to: "/iec"
    },*/
    {
        label: "Компании",
        icon: <IconBriefcase size={20} />,
        to: "/companies",
    },
    {
        label: "Информация",
        icon: <IconInfoCircle size={20} />,
        to: "/info",
    },
];

const adminLinks: MainLinkProps[] = [
    {
        label: "Карта",
        icon: <IconMap2 size={20} />,
        to: "/map",
    },
    {
        label: "Мониторинг",
        icon: <IconHeartRateMonitor size={20} />,
        to: "/monitoring",
    },
    {
        label: "Устройства",
        icon: <IconDeviceDesktopAnalytics size={20} />,
        to: "/devices",
    },
    {
        label: "Работа с графикой",
        icon: <IconGraph size={20} />,
        to: "/graphic",
    },
    {
        label: "Отчеты",
        icon: <IconReportAnalytics size={20} />,
        to: "/reports",
    },
    /*{
        label: "IEC",
        icon: <IconReportSearch size={20} />,
        to: "/iec"
    },*/
    {
        label: "Наладка",
        icon: <IconSettings size={20} />,
        to: "/setup",
    },
    // {
    //     label: "Админ",
    //     icon: <IconAlien size={20} />,
    //     to: "/admin",
    // },
    {
        label: "Прошивка",
        icon: <IconAccessPoint size={20} />,
        to: "/firmware",
    },
    {
        label: "Компании",
        icon: <IconBriefcase size={20} />,
        to: "/companies",
    },
    {
        label: "Информация",
        icon: <IconInfoCircle size={20} />,
        to: "/info",
    },
];

export const SideNav: React.FC = () => {
    const { user, logout } = useAuth();
    // const [notifDrawerOpen, toggleNotifDrawer] = useBoolean(false);
    const { t } = useTranslation();
    const [isCreateDeviceDrawerOpen, setCreateDeviceDrawerOpen] = useState(false);
    // const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const [expanded, setExpanded] = useDisclosure(false);

    const ref = useClickOutside(setExpanded.close);

    const [isChangeLanguageDrawerOpen, setChangeLanguageDrawerOpen] = useState(false);

    return (
        <>
            <Navbar
                ref={ref}
                p="xs"
                width={{ base: expanded ? 301 : 81 }}
                sx={(theme) => ({
                    zIndex: 200,
                    position: "fixed",
                    boxShadow: expanded ? theme.shadows.md : undefined,
                    "&:hover": {
                        transition: "width 0.2s ease-in-out",
                    },
                })}
            >
                <Navbar.Section>
                    <Group position={expanded ? "left" : "center"}>
                        {/* <UnstyledButton
                            component={Link}
                            to="/monitoring"
                            sx={(theme) => ({
                                display: "flex",
                                width: "100%",
                                padding: "10px",
                                paddingLeft: expanded ? "18px" : "10px",
                                borderRadius: theme.radius.sm,
                                color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
                            })}
                        >
                            {expanded ? <Logo /> : <Logo iconOnly />}
                        </UnstyledButton> */}
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                padding: "10px",
                                paddingLeft: expanded ? "18px" : "10px",
                                borderRadius: "3px",
                                position: "relative"
                            }}
                        >
                            <NotificationsLayout expanded={expanded}/>
                        </div>
                    </Group>
                </Navbar.Section>
                {user?.Role !== RoleEnum.User ? (
                    <Navbar.Section grow mt="0">
                        {adminLinks.map((link) => (
                            <MainLink {...link} showLabel={expanded} key={link.to} />
                        ))}
                    </Navbar.Section>
                ) : (
                    <Navbar.Section grow mt="0">
                        {userLinks.map((link) => (
                            <MainLink {...link} showLabel={expanded} key={link.to} />
                        ))}
                    </Navbar.Section>
                )}
                <Navbar.Section>
                    <MainLink
                        label="Изменить язык"
                        showLabel={expanded}
                        icon={<IconWorld />}
                        onClick={() => {
                            setChangeLanguageDrawerOpen(true);
                        }}
                    />
                    <MainLink
                        label="Добавить устройство"
                        showLabel={expanded}
                        icon={<IconPlus />}
                        onClick={() => {
                            setCreateDeviceDrawerOpen(true);
                        }}
                    />
                    {/* <ActionIcon
                            size="lg"
                            variant="filled"
                            color="dark"
                            onClick={() => {
                                setCreateDeviceDrawerOpen(true);
                            }}
                        >
                            <IconPlus />
                        </ActionIcon> */}
                    {/* <MainLink
                    onClick={() => toggleColorScheme()}
                    label={colorScheme === "dark" ? t("Switch to Default theme") : t("Switch to Dark theme")}
                    icon={colorScheme === "dark" ? <IconSun size={18} /> : <IconMoonStars size={18} />}
                    showLabel={expanded}
                /> */}
                    {user && (
                        <Menu width={200} position="top-start">
                            <Menu.Target>
                                <UnstyledButton
                                    sx={(theme) => ({
                                        flexWrap: "nowrap",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        borderRadius: theme.radius.sm,
                                        color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
                                        width: "100%",
                                        gap: theme.spacing.xs,
                                        alignItems: "center",

                                        "&:hover": {
                                            backgroundColor:
                                                theme.colorScheme === "dark"
                                                    ? theme.colors.dark[6]
                                                    : theme.colors.gray[1],
                                        },
                                    })}
                                >
                                    <Avatar m={10}>{user.UserName ? user.UserName.substring(0, 1) : ''}</Avatar>
                                    <Transition mounted={expanded} duration={500} transition="slide-right">
                                        {(style) => (
                                            <Stack spacing={0} style={style}>
                                                <Text weight="bold">{user.UserName}</Text>
                                                <Text size="xs">{user.Email}</Text>
                                            </Stack>
                                        )}
                                    </Transition>
                                </UnstyledButton>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item component={Link} to="/profile" icon={<IconUser size={14} />}>
                                    {t("Профиль")}
                                </Menu.Item>

                                <Divider />

                                <Menu.Item onClick={() => logout()} icon={<IconLogout size={14} />}>
                                    {t("Выйти")}
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    )}

                    <Divider mt="md" />
                    <Group position="right">
                        <UnstyledButton
                            mt="md"
                            sx={(theme) => ({
                                display: "flex",
                                padding: "12px 17px 12px 17px",
                                justifyContent: expanded ? "flex-end" : "center",
                                borderRadius: theme.radius.sm,
                                color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

                                "&:hover": {
                                    backgroundColor:
                                        theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[1],
                                },
                            })}
                            onClick={() => setExpanded.toggle()}
                        >
                            <ThemeIcon
                                sx={{
                                    backgroundColor: "inherit",
                                    color: "inherit",
                                }}
                                variant="light"
                            >
                                {expanded ? <IconChevronsLeft /> : <IconChevronsRight />}
                            </ThemeIcon>
                        </UnstyledButton>
                    </Group>
                </Navbar.Section>
            </Navbar>
            <Drawer
                opened={isChangeLanguageDrawerOpen}
                onClose={() => setChangeLanguageDrawerOpen(false)}
                title={t("Изменить язык")}
                padding="md"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <ChangeLanguageDrawer />
            </Drawer>
            <Drawer
                opened={isCreateDeviceDrawerOpen}
                onClose={() => setCreateDeviceDrawerOpen(false)}
                title={t("Новое устройство")}
                padding="md"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <CreateDeviceDrawer onComplete={() => setCreateDeviceDrawerOpen(false)} />
            </Drawer>
        </>
    );
};
