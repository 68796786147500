import { ActionIcon, Badge, Group, Paper, Stack, Title } from "@mantine/core"
import { GraphicSettings } from "./GraphicList"
import { useTowerChartData } from "../api/getTowerChartData"
import { useEffect, useState } from "react"
import { getChartDataCommand, useChartData } from "../api/getChartData"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import Chart from "react-apexcharts";
import { uniq } from "lodash"
import _ from "lodash"
import { t } from "msw/lib/glossary-de6278a9"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useDevices } from "src/features/devices/api/getDevices"
import { ChannelsTranslations } from "./GraphicFormNew"
import { СhannelDeviceModelEnum } from "src/features/devices/types"

const colors = [
    // "#6929c4",
    // "#1192e8",
    // "#005d5d",
    // "#9f1853",
    // "#520408",

    "#BF2600",
    "#FF8B00",
    "#006644",
    "#008DA6",
    "#403294",
    "#0747A6",
    "#091E42",

    // "#DE350B",
    // "#FF991F",
    // "#00875A",
    // "#00A3BF",
    // "#5243AA",
    // "#0052CC",
    // "#253858",

    // "#FF5630",
    // "#FFAB00",
    // "#36B37E",
    // "#00B8D9",
    // "#8777D9",
    // "#0065FF",
    // "#42526E",
];

type GraphicProps = {
    graphicSettings:    GraphicSettings,
    onDelete?:          ()=>void,
    onEdit?:            () => void,
}

export const Graphic: React.FC<GraphicProps> = ({graphicSettings, onDelete, onEdit}) => {

    const [ graphicReqCommand, setGraphicReqCommand ] = useState<getChartDataCommand>()

    const { t } = useTranslation()

    const { data: devices } = useDevices() 

    useEffect(()=>{
        // setGraphicReqCommand({
        //     DeviceIds: graphicSettings.targetDevices.map(x=>x.device),
        //     OverheadPowerLineIds: graphicSettings.targetDevices.filter(x=>x.OPL && x.OPL > 0).map(x=>x.OPL) as number[],
        //     OverheadPowerLineTowerIds: graphicSettings.targetDevices.filter(x=>x.Tower && x.Tower > 0).map(x=>x.Tower) as number[],
        //     ChannelIds: graphicSettings.targetDevices.map(x=>x.ChannelId),
        //     averaging: graphicSettings.averaging ? graphicSettings.averaging : undefined,
        //     startDt: graphicSettings.startDt ? graphicSettings.startDt : undefined,
        //     endDt: graphicSettings.endDt ? graphicSettings.endDt : undefined,
        // })
        setGraphicReqCommand({
            DeviceIds: graphicSettings.Devices,
            OverheadPowerLineIds: graphicSettings.OPLs,
            OverheadPowerLineTowerIds: graphicSettings.Towers,
            ChannelIds: graphicSettings.Channels,
            averaging: graphicSettings.averaging ? graphicSettings.averaging : undefined,
            startDt: graphicSettings.startDt ? graphicSettings.startDt : undefined,
            endDt: graphicSettings.endDt ? graphicSettings.endDt : undefined,
        })
    }, [graphicSettings])

    const { data: chartData } = useChartData(graphicReqCommand)

    const [ formedData, setFormedData ] = useState<{data: {name: string, data: number[]}[], timestamps: Date[]}>({data: [], timestamps: []})

    // const updateFormedData = () => {
    //     let tmp = []
    //     let timestamps = new Set()
    //     for (let i of chartData?.ChartChannelData ? chartData.ChartChannelData : []){
    //         if (i && i.OverheadPowerLineTowerChartChannelDtos) 
    //             for (let k of _.uniq(i.OverheadPowerLineTowerChartChannelDtos)){
    //                 if (
    //                     graphicSettings.targetDevices.map(elem => elem.device).includes(k.DeviceId)
    //                     && tmp.filter(x=>x.name === graphicSettings.targetDevices.filter(elem=>elem.device === k.DeviceId && elem.ChannelId === i.DeviceModelChannelId)[0].name).length === 0
    //                 ) {
    //                     tmp.push({
    //                         name: graphicSettings.targetDevices.filter(elem=>elem.device === k.DeviceId && elem.ChannelId === i.DeviceModelChannelId)[0].name,
    //                         data: k.Values ? k.Values : [],
    //                     })
    //                     if (k.Timestamps)
    //                         k.Timestamps.forEach(elem => {
    //                             timestamps.add(elem)
    //                     })
    //                 }
    //             }
    //     }
    //     let timestampsArr = Array.from(timestamps)
    //     timestampsArr.sort(((a, b) => new Date(a as Date).valueOf() - new Date(b as Date).valueOf()))
    //     setFormedData(
    //         {
    //             data: tmp,
    //             timestamps: timestampsArr as Date[],
    //         }
    //     )
    // }

    const updateFormedData = () => {
        let tmp = []
        let timestamps = new Set()
        for (let i of chartData?.ChartChannelData || []){
            if (i && i.OverheadPowerLineTowerChartChannelDtos) 
                for (let k of i.OverheadPowerLineTowerChartChannelDtos){
                    tmp.push({
                        name: `${devices?.filter(x => x.Id === k.DeviceId)[0].Name} ${t(ChannelsTranslations[i.DeviceModelChannelId as СhannelDeviceModelEnum])}`,
                        data: k.Values ? k.Values : [],
                    })
                    if (k.Timestamps)
                        k.Timestamps.forEach(elem => {
                            timestamps.add(elem)
                    })
                }
        }
        let timestampsArr = Array.from(timestamps)
        timestampsArr.sort(((a, b) => new Date(a as Date).valueOf() - new Date(b as Date).valueOf()))
        setFormedData(
            {
                data: tmp,
                timestamps: timestampsArr as Date[],
            }
        )
    }

    useEffect(()=>{
        updateFormedData()
    }, [chartData])

    return(
        <Paper p="md" shadow="sm" style={{overflow: "hidden", marginBottom: 16, boxSizing: "border-box", flexGrow: 1}}>
            <Group style={{width: "100%", justifyContent: "space-between"}}>
                <Title order={3} style={{ wordBreak: "break-word" }}>
                    {graphicSettings.Name}
                </Title>
                <Group>
                    <ActionIcon onClick={onEdit} size="lg" variant="default">
                        <IconEdit size={20} />
                    </ActionIcon>
                    <ActionIcon
                        size="lg"
                        variant="outline"
                        color="red"
                        onClick={onDelete}
                    >
                        <IconTrash size={20} />
                    </ActionIcon>
                </Group>
            </Group>
            <Stack>
                <Group>
                    {graphicSettings.averaging ?
                        <Badge
                            radius="xs"
                            variant="filled"
                        >
                            {`${t("Усреднение")}: ${
                                graphicSettings.averaging > 60 ?
                                    Math.floor(graphicSettings.averaging / 60)
                                : graphicSettings.averaging
                            } ${
                                graphicSettings.averaging > 60 ? t("часа") : t("минут")}
                            `}
                        </Badge>
                    :
                        <Badge
                            radius="xs"
                            variant="filled"
                        >
                            {t("Без усреднения")}
                        </Badge>
                    }
                    <Badge
                        radius="xs"
                        variant="filled"
                    >
                        {chartData ? dayjs(chartData.StartDt || '').format("DD.MM.YY HH:mm") : ''}
                    </Badge>
                    <Badge
                        radius="xs"
                        variant="filled"
                    >
                        {chartData ? dayjs(chartData.EndDt || '').format("DD.MM.YY HH:mm") : ''}
                    </Badge>
                </Group>

                <Chart
                    options={{
                        chart: {
                            id: "basic-bar",
                            toolbar: {
                                tools: {
                                  pan: true
                                }
                            }
                        },
                        stroke: {
                            width: 2,
                        },
                        colors: formedData && formedData.data.map((x,i) => 
                            colors[i % 7]
                        ),
                        xaxis: {
                            type: "datetime",
                            categories: formedData.timestamps,
                            tickAmount: 30,
                            axisBorder: {
                                show: true,
                            },
                        },
                        yaxis: formedData && formedData.data.map((x,i) => ({
                            axisBorder: {
                                show: true,
                                color: colors[i % 7],
                            },
                            title: {
                                text: t(x.name),
                                style: {
                                    color: colors[i % 7],
                                },
                            },
                        }))
                    }}
                    series={Array.isArray(formedData && formedData.data) ? formedData.data : []}
                    type="line"
                    width="100%"
                    height="500"
                />
            </Stack>
        </Paper>
    )
} 
