import { Button, Chip, Group, SegmentedControl, Select, TextInput, Tooltip } from "@mantine/core"
import { OPL_small, OverheadPowerLineTowersPhaseEnum, Tower_small } from "src/features/OPL/types"
import { useForm } from "@mantine/form"
import { useOPLs } from "src/features/OPL/api/getOPLs"
import { useTowers } from "src/features/OPL/api/getTowers"
import { SimpleSearchList } from "src/features/companies/components/OP"
import { showNotification } from "@mantine/notifications"
import { useTower } from "src/features/OPL/api/getTower"
import { DatePicker } from "@mantine/dates"
import { useDevices } from "src/features/devices/api/getDevices"
import { GraphicSettings } from "./GraphicList"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { IconX } from "@tabler/icons-react"
import { DeviceDto, СhannelDeviceModelEnum } from "src/features/devices/types"

export type targetDeviceChannel = {
    OPL?: number | null,
    Tower?: number | null,
    Phase?: OverheadPowerLineTowersPhaseEnum | null,
    ChannelId: number,
    device: number,
    name: string,
}

type GraphicFormProps = {
    index:      number,
    close:      ()=>void,
    save:       (settings: GraphicSettings) => void,
    editing?:   {
        settings: GraphicSettings & {index: number},
        stop:     (settings?: GraphicSettings & {index: number}) => void,
    }
}

export const ChannelsTranslations = {
    [СhannelDeviceModelEnum.Current]: "Измеренное значение тока, А",
    [СhannelDeviceModelEnum.Temperature]: "Измеренное значение температуры провода ВЛ",
    [СhannelDeviceModelEnum.Angle]: "Измеренное значение угла стрелы провеса, градусы",
    [СhannelDeviceModelEnum.VibrationAmplitude]: "Измеренное значение амплитуды вибрации, mg",
    [СhannelDeviceModelEnum.VibrationFrequency]: "Измеренное значение частоты вибрации, Гц",
    [СhannelDeviceModelEnum.CurrentPeriodsDifference]: "Разница периодов тока до КЗ и при КЗ, количество периодов дискретизации (0,2mS)",
    [СhannelDeviceModelEnum.CurrentShortCircuit]: "Значение тока короткого замыкания, А",
    [СhannelDeviceModelEnum.EventCode]: "Код события",
    [СhannelDeviceModelEnum.IceV2IcePerMeter]: "КГ льда на метр",
    [СhannelDeviceModelEnum.IceV2IcePerSpan]: "КГ льда на пролет",
    [СhannelDeviceModelEnum.BreakDetectionState]: "Состояние обнаружения обрыва",
    [СhannelDeviceModelEnum.WireAmplitude]: "Измеренное значение амплитуды пляски",
    [СhannelDeviceModelEnum.IonistorsVoltage]: "Напряжение на ионисторах В",
    [СhannelDeviceModelEnum.GSMSignal]: "Уровень сигнала GSM",
    [СhannelDeviceModelEnum.MeasurementTimeStamp]: "Метка времени измерения или события",
    [СhannelDeviceModelEnum.GSMResultCode]: "GSM result code",
    [СhannelDeviceModelEnum.RebootReasonCode]: "Код причины последней перезагрузки",
    [СhannelDeviceModelEnum.ICCID]: "ICCID",
    [СhannelDeviceModelEnum.MACAdress]: "MAC adress",
    [СhannelDeviceModelEnum.SoftwareVersion]: "Версия ПО",
    [СhannelDeviceModelEnum.Dimension]: "Габарит",
    [СhannelDeviceModelEnum.AccelerometerTemperature]: "Измеренное значение температуры акселерометра",
    [СhannelDeviceModelEnum.PeriodDurationBeforeEvent]: "Длительность периода тока до события",
    [СhannelDeviceModelEnum.PeriodDurationAtEvent]: "Длительность периода тока в момент события",
    [СhannelDeviceModelEnum.PeriodDurationAfterEvent]: "Длительность периода тока после события",
    [СhannelDeviceModelEnum.IceV2AngleReal]: "Угол, измеренный после калибровки",
    [СhannelDeviceModelEnum.IceV3TangentAnglePredictedByWireModel]: "a°М (Угол касательной предсказанный моделью провода)",
    [СhannelDeviceModelEnum.IceV3AngleMeasuredByAccelerometer]: "aC (Угол измеренный акселерометром приведенный к модели)",

}

export const GraphicForm: React.FC<GraphicFormProps> = ({index, close, save, editing}) => {

    const currentDate = new Date()
    const millisecondsInDay = 24 * 60 * 60 * 1000;

    const form = useForm<GraphicSettings>({initialValues: 
        editing ? 
            {
                Name: editing.settings.Name,
                targetDevices: editing.settings.targetDevices,
                startDt: editing.settings.startDt ? new Date(editing.settings.startDt as unknown as string) : new Date(currentDate.getTime() - millisecondsInDay),
                endDt: editing.settings.endDt ? new Date(editing.settings.endDt as unknown as string) : currentDate,
                averaging: editing.settings.averaging || 0,
                OPLs: editing.settings.OPLs,
                Towers: editing.settings.Towers,
                Devices: editing.settings.Devices,
                Channels: editing.settings.Channels,
            }
        :
            {
                Name: `График ${index + 1}`,
                targetDevices: [],
                startDt: new Date(currentDate.getTime() - millisecondsInDay),
                endDt: currentDate,
                averaging: 0,
                OPLs: [],
                Towers: [],
                Devices: [],
                Channels: [],
            }
    })

    const { t } = useTranslation()

    const { data: OPLs } = useOPLs()
    const { data: Towers } = useTowers()
    const { data: devices } = useDevices()

    const [type, setType] = useState("OPL")

    const [addingOPL, setAddingOPL] = useState<OPL_small | undefined>()
    const [addingTower, setAddingTower] = useState<Tower_small | undefined>()
    const [addingDevice, setAddingDevice] = useState<DeviceDto | undefined>()
    const [addingChannel, setAddingChannel] = useState<СhannelDeviceModelEnum | null>()

    const selectHandler = (OPL?: number, Tower?: number, Device?: number) => {
        if (OPL)
            setAddingOPL(OPLs?.filter(x=>x.Id === OPL)[0])
        if (Tower)
            setAddingTower(Towers?.filter(x=>x.Id === Tower)[0])
        if (Device)
            setAddingDevice(devices?.filter(x=>x.Id === Device)[0])
    }

    const handleSelectAveraging = (value: string) => {
        form.setFieldValue("averaging", value !== '' ? parseInt(value) : null)
    }

    function getChartTimes() {
        return (
            <div className="md:space-x-2 md:flex">
                <Select
                    id="veraging-select"
                    value={form.values.averaging ? form.values.averaging.toString() : "0"}
                    onChange={handleSelectAveraging}
                    label={t("Усреднение").toString()}
                    data={[
                        { value: "0", label: t("Без усреднения") },
                        { value: "5", label: t("5 минут") },
                        { value: "10", label: t("10 минут") },
                        { value: "30", label: t("30 минут") },
                        { value: "60", label: t("1 час") },
                        { value: "120", label: t("2 часа") },
                        { value: "240", label: t("4 часа") },
                    ]}
                />
                <DatePicker
                    value={form.values.startDt}
                    maxDate={new Date(Math.min(form.values.endDt?.getTime() || currentDate.getTime(), currentDate.getTime())) ? new Date(Math.min(form.values.endDt?.getTime() || currentDate.getTime(), currentDate.getTime())) : undefined}
                    inputFormat="DD.MM.YYYY"
                    onChange={x=>form.setFieldValue("startDt", x)}
                    label={t("От").toString()}
                />
                <DatePicker
                    value={form.values.endDt}
                    minDate={form.values.startDt ? form.values.startDt : undefined}
                    inputFormat="DD.MM.YYYY"
                    onChange={x=>form.setFieldValue("endDt", x)}
                    label={t("До").toString()}
                />
            </div>
        );
    }

    useEffect(()=>{
        setAddingOPL(undefined)
        setAddingTower(undefined)
        setAddingDevice(undefined)
        setAddingChannel(undefined)
    }, [type])

    return(
        <form
            onSubmit={(event)=>{
                event.preventDefault(); 
                if (editing)
                    editing.stop({...form.values, index: editing.settings.index})
                else
                    save(form.values); 
                close(); 
                form.reset();
            }}
        >
            <div className="space-y-2 flex-auto mb-6">
                <span className="mb-2 text-base font-medium text-gray-900">
                    {t("График")}
                </span>
                <TextInput
                    id="Name"
                    name="Name"
                    placeholder={t("Название")}
                    label={t("Название")}
                    {...form.getInputProps("Name")}
                />
            </div>

            <div className="space-y-2 flex-auto mb-6">
                <span className="mb-2 text-base font-medium text-gray-900">
                    {t("ВЛ, опора или устроство")}
                </span><br/>
                <SegmentedControl
                    value={type}
                    data={[
                        {value: "OPL", label: "ВЛ"},
                        {value: "Tower", label: "Опора"},
                        {value: "Device", label: "Устройство"},
                    ]}
                    onChange={setType}
                />
            </div>

            <div className="space-y-4 flex-auto">
                <span className="mb-2 text-base font-medium text-gray-900">
                    {t("Каналы")}
                </span>

                {type === "Tower" && <>
                    <div>
                        <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                            {t("Выберите ВЛ")}
                        </span>
                        <SimpleSearchList
                            items={OPLs ? OPLs : []}
                            setValue={x => selectHandler(x, undefined, undefined)}
                            chosenId={addingOPL?.Id || null}
                        />
                    </div>
                
                    {addingOPL ?<div>
                        <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                            {t("Выберите опору")}
                        </span>
                        <SimpleSearchList
                            items={Towers ? Towers.filter(x => x.OverheadPowerLineId === addingOPL.Id) : []}
                            setValue={x => selectHandler(undefined, x, undefined)}
                            chosenId={addingTower?.Id || null}
                        /></div>
                    :''}
                </>}

                {type === 'Device' &&
                    <div>
                        <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                            {t("Выберите устройство")}
                        </span>
                        <SimpleSearchList
                            items={devices ? devices : []}
                            setValue={x => {selectHandler(undefined, undefined, x)}}
                            chosenId={addingDevice?.Id || null}
                        />
                    </div>
                }
                {type === "OPL" && 
                    <div>
                        <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                            {t("Выберите ВЛ")}
                        </span>
                        <SimpleSearchList
                            items={OPLs ? OPLs : []}
                            setValue={x => {{selectHandler(x, undefined, undefined)}}}
                            chosenId={addingOPL?.Id || null}
                        />
                    </div>
                }
                
                {((type === "OPL" && addingOPL) || (type === "Tower" && addingTower) || (type === "Device" && addingDevice)) && 
                    <Button 
                        className="mt-6" 
                        variant="outline" 
                        style={{width: "100%"}} 
                        onClick={()=>{
                            if (type === "OPL" && addingOPL && !form.values.OPLs.includes(addingOPL.Id)){
                                let tmp = form.values.OPLs
                                tmp.push(addingOPL?.Id)
                                form.setFieldValue("OPLs", tmp)
                            }
                            if (type === "Tower" && addingTower && !form.values.Towers.includes(addingTower.Id)){
                                let tmp = form.values.Towers
                                tmp.push(addingTower?.Id)
                                form.setFieldValue("Towers", tmp)
                            }
                            if (type === "Device" && addingDevice && !form.values.Devices.includes(addingDevice.Id)){
                                let tmp = form.values.Devices
                                tmp.push(addingDevice?.Id)
                                form.setFieldValue("Devices", tmp)
                            }
                        }}
                    >
                        {t("Добавить")}
                    </Button>
                }
            </div>

            {(form.values.OPLs && form.values.OPLs.length > 0) && <>
                <div className="space-y-2 flex-auto mt-6 mb-6">
                    <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                        {"Выбранные ВЛ:"}
                    </span><br/>
                    <Chip.Group>
                        {form.values.OPLs.map(x=>
                            <Chip>
                                <Group>
                                    <span style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "fit-content",
                                    }}>
                                        {OPLs?.filter(y=>y.Id === x)[0].Name}
                                    </span>
                                    <IconX 
                                        size={18} 
                                        color="#a1aab3"
                                        onClick={()=>{
                                            form.setFieldValue("OPLs", form.values.OPLs.filter(y=>y !== x))
                                        }}
                                    />
                                </Group>
                            </Chip>
                        )}
                    </Chip.Group>
                </div>
            </>}

            {(form.values.Towers && form.values.Towers.length > 0) && 
                <div className="space-y-2 flex-auto mt-6 mb-6">
                    <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                        {"Выбранные опоры:"}
                    </span><br/>
                    <Chip.Group>
                        {form.values.Towers.map(x=>
                            <Chip>
                                <Group>
                                    <span style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "fit-content",
                                    }}>
                                        {`${OPLs?.filter(y=> y.Id === Towers?.filter(y=>y.Id === x)[0].OverheadPowerLineId)[0].Name}/${Towers?.filter(y=>y.Id === x)[0].Name}`}
                                    </span>
                                    <IconX 
                                        size={18} 
                                        color="#a1aab3"
                                        onClick={()=>{
                                            form.setFieldValue("Towers", form.values.Towers.filter(y=>y !== x))
                                        }}
                                    />
                                </Group>
                            </Chip>
                        )}
                    </Chip.Group>
                </div>}

            {(form.values.Devices && form.values.Devices.length > 0) && 
                <div className="space-y-2 flex-auto mt-6 mb-6">
                    <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                        {"Выбранные устройства:"}
                    </span><br/>
                    <Chip.Group>
                        {form.values.Devices.map(x=>
                            <Chip>
                                <Group>
                                    <span style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "fit-content",
                                    }}>
                                        {devices?.filter(y=>y.Id === x)[0].Name}
                                    </span>
                                    <IconX 
                                        size={18} 
                                        color="#a1aab3"
                                        onClick={()=>{
                                            form.setFieldValue("Devices", form.values.Devices.filter(y=>y !== x))
                                        }}
                                    />
                                </Group>
                            </Chip>
                        )}
                    </Chip.Group>
                </div>
            }

            {((form.values.Devices && form.values.Devices.length > 0) || (form.values.Towers && form.values.Towers.length > 0) || (form.values.OPLs && form.values.OPLs.length > 0)) && 
                <div className="mt-6 mb-6">
                    <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                        {t("Выберите канал")}
                    </span>
                    <SimpleSearchList
                        items={Object.entries(СhannelDeviceModelEnum).map(x=> ({Id: x[0], Name: t(ChannelsTranslations[x[0] as unknown as СhannelDeviceModelEnum])}))}
                        setValue={x => {setAddingChannel(x)}}
                        chosenId={addingChannel as number}
                    />
                    <Button 
                        className="mt-6" 
                        variant="outline" 
                        style={{width: "100%"}} 
                        onClick={()=>{
                            if (addingChannel && !form.values.Channels.includes(addingChannel as number)){
                                let tmp = form.values.Channels
                                tmp.push(addingChannel as number)
                                form.setFieldValue("Channels", tmp)
                            }
                        }}
                    >
                        {t("Добавить")}
                    </Button>
                </div>
            }

            {(form.values.Channels && form.values.Channels.length > 0) && 
                <div className="space-y-2 flex-auto mt-6 mb-6">
                    <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                        {"Выбранные каналы:"}
                    </span><br/>
                    <Chip.Group>
                        {form.values.Channels.map(x=>
                            <Chip>
                                <Group>
                                    <span style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "fit-content",
                                    }}>
                                        {t(ChannelsTranslations[x as СhannelDeviceModelEnum])}
                                    </span>
                                    <IconX 
                                        size={18} 
                                        color="#a1aab3"
                                        onClick={()=>{
                                            form.setFieldValue("Channels", form.values.Channels.filter(y=>y !== x))
                                        }}
                                    />
                                </Group>
                            </Chip>
                        )}
                    </Chip.Group>
                </div>
            }
            
            {(((form.values.Devices && form.values.Devices.length > 0) || (form.values.Towers && form.values.Towers.length > 0) || (form.values.OPLs && form.values.OPLs.length > 0)) && (form.values.Channels && form.values.Channels.length > 0)) && <>
                {getChartTimes()}
                <Button style={{width: "100%", marginTop: 6}} type="submit">
                    {t("Создать график")}
                </Button>
            </>}
        </form>
    )
}