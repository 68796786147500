import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { NotificationsDto } from "../types";

export const getDeviceNotifications = (device_id: number | null): Promise<NotificationsDto[]>|undefined => {
    if (device_id)
        return axios.get(`/Devices/${device_id ? device_id : 0}/notifications`);
};

type QueryFnType = typeof getDeviceNotifications;

export const useDeviceNotifications = (device_id: number | null) => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["deviceNotifications"+(device_id ? device_id : "root")],
        queryFn: () => getDeviceNotifications(device_id),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                message: t("Ошибка при загрузке уведомлений"),
                title: t("Серверная ошибка"),
            });
        },
    });

    return result
};
