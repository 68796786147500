import { useEffect, useState } from "react"
import { MonitoringTowersHead } from "../features/monitoring_towers/components/MonitoringTowersHead"
import { CurrentTower } from "src/features/monitoring_towers/components/CurrentTower"
import { Mock } from "src/features/monitoring_towers/components/Mock"
import { useOPLs } from "src/features/OPL/api/getOPLs"
import { SearchingList } from "src/features/monitoring_towers/components/SearchingList"
import { useTowers } from "src/features/OPL/api/getTowers"
import { useTranslation } from "react-i18next"
import { useOPL } from "src/features/OPL/api/getOPL"
import { useTower } from "src/features/OPL/api/getTower"
import ListCollapser from "src/components/ListCollapser/ListCollapser"
import { useTowersStore } from "src/features/OPL/store"
import { Button, Paper } from "@mantine/core"
import { IconArrowLeft } from "@tabler/icons-react"
import { useNavigate } from "react-router"
import { CreationDrawer } from "src/features/companies/components/OP"
import { useAuth } from "src/lib/auth"
import { useDeleteOPL } from "src/features/OPL/api/deleteOPL"
import { useDeleteTower } from "src/features/OPL/api/deleteTower"
import { CurrentOPL } from "src/features/monitoring_towers/components/CurrentOPL"

type MonitoringTowersViewProps = {
    storeSelectedOPLid?:     number,
    storeSelectedTowerId?:   number
}

export const MonitoringTowersView: React.FC<MonitoringTowersViewProps> = ({storeSelectedOPLid, storeSelectedTowerId}) => {

    const { t } = useTranslation()

    const [ tab, setTab ] = useState("")

    const { data: OPLs } = useOPLs()

    const { data: Towers } = useTowers()

    // const [ selectedOPLid, setSelectedOPLid ] = useState<number | null>(null)

    // const [ selectedTowerId, setSelectedTowerId ] = useState<number | null>(null)

    const selectedTower = useTowersStore((state=>state))

    const { data: currentOPL } = useOPL(selectedTower && selectedTower.selectedOPLid ? selectedTower.selectedOPLid : undefined)

    const { data: currentTower } = useTower(selectedTower && selectedTower.selectedTowerId ? selectedTower.selectedTowerId : undefined)

    const { unselectTower } = useTowersStore()

    const navigate = useNavigate()

    const deleteOPMutation = useDeleteOPL()

    const deleteTowerMutation = useDeleteTower()

    const [ editing, setEditing ] = useState(false)

    const { user } = useAuth();

    const tabs = [
        { label: t("Гололёд"),                 value: "GIO" },
        { label: t("КЗ"),                      value: "KZ" },
        { label: t("Габарит и обрыв"),         value: "GP" },
        { label: t("Техническая информация"),  value: "WP" },
        { label: t("Уведомления"),             value: "notifications" },
    ]

    const [forcingTowerId, setForcingTowerId] = useState<number[] | null>()

    const handle_setSelectedTowerId = (id: number | null) => {
        if (id){
            setForcingTowerId([id, 1])
        }
    }

    useEffect(()=>{
        if (selectedTower.selectedOPLid && selectedTower.selectedTowerId)
            setForcingTowerId([selectedTower.selectedTowerId, 1])
    }, [])

    useEffect(()=>{
        if (selectedTower.selectedTowerId)
            setTab("GIO")
        else
            setTab("list")
    }, [selectedTower.selectedTowerId])

    return(
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100vh",
            }}
        >
            <ListCollapser enabled>
                <>
                <div className="h-full">
                    <SearchingList
                        label = {t("Мониторинг")}
                        levels
                        elements={[]}
                        id={null}
                        ids = {[selectedTower.selectedOPLid ? selectedTower.selectedOPLid : null, selectedTower.selectedTowerId ? selectedTower.selectedTowerId : null]}
                        setId = {null}
                        elements_lvl={[(OPLs ? OPLs : []), (Towers ? Towers.filter(x=>x.OverheadPowerLineId === selectedTower.selectedOPLid) : [])]}
                        setIds={[selectedTower.selectOPL, (id)=> {selectedTower.selectTower(Towers ? Towers.filter(x=>x.Id === id)[0] : null) }]}
                        forcing={forcingTowerId ? forcingTowerId : undefined}
                        dropForcing={()=>setForcingTowerId(null)}
                        types={[t("ВЛ"), t("Опора")]}
                    />
                </div>
                </>
            </ListCollapser>

            <div
                className="p-4"
                style={{
                    width: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                }}
            >
                <MonitoringTowersHead
                    tab={tab}
                    select_tab={setTab}
                    back_button={
                        <Button variant="default" leftIcon={<IconArrowLeft />} onClick={()=>{unselectTower(); navigate("/map")}}>
                            {t("К карте").toString()}
                        </Button>
                    }
                    names_comments={currentOPL && currentTower ? 
                        [{
                            label:      t("ВЛ"),
                            name:       currentOPL?.Name || '-',
                            comment:    currentOPL?.Comment || '-',
                        },
                        {
                            label:      t("Опора"),
                            name:       currentTower?.Name || '-',
                            comment:    currentTower?.Comment || '-',
                        },]
                        :
                        currentOPL ? 
                        [{
                            label:      t("ВЛ"),
                            name:       currentOPL?.Name || '-',
                            comment:    currentOPL?.Comment || '-',
                        },]
                        : [{
                            label:      t(""),
                            name:       'Опора не выбрана',
                            comment:    'Выберите опору',
                        },]
                    }
                    editing={selectedTower.selectedOPLid ? {
                        state: editing,
                        start: ()=>setEditing(true),
                    } : undefined}
                    deleting={selectedTower.selectedOPLid ? {
                        start: ()=>{
                            if (selectedTower.selectedTowerId){
                                selectedTower.unselectTower()
                                deleteTowerMutation.mutateAsync({id: selectedTower.selectedTowerId})}
                            else{
                                selectedTower.selectOPL(null)
                                selectedTower.unselectTower()
                                if (selectedTower.selectedOPLid)
                                    deleteOPMutation.mutateAsync({id: selectedTower.selectedOPLid})
                            }
                        },
                    } : undefined}
                    showTabs={!!selectedTower.selectedOPLid}
                    tabs={selectedTower.selectedTowerId ? tabs 
                        : selectedTower.selectedOPLid ? [
                            {label: t("Список опор"), value: "list"},
                            {label: t("Уведомления"), value: "notifications"},
                        ] :
                        []}
                />

                {currentTower ?
                    <CurrentTower
                        tab={tab}
                        tower={currentTower}
                    />
                : currentOPL ?
                    <CurrentOPL
                        tab={tab} 
                        OPL={currentOPL}
                        setId={handle_setSelectedTowerId}
                        id={selectedTower.selectedTowerId ? selectedTower.selectedTowerId : null}
                    />
                : 
                    <Paper className="mb-4" p="md" shadow="sm" style={{overflowY: "scroll", overflowX: "hidden", marginBottom: 0, boxSizing: "border-box", flexGrow: 1}}></Paper>
                }

                <CreationDrawer
                    opened={editing}
                    close=      {()=>setEditing(false)}
                    company=    {(currentTower) ? currentTower.CompanyId : 0}
                    editing
                    tab=        {selectedTower.selectedTowerId ? 'OPtower' : 'OP'}
                    id=         {selectedTower.selectedTowerId ? selectedTower.selectedTowerId : selectedTower.selectedOPLid ? selectedTower.selectedOPLid : undefined}
                />
            </div>
        </div>
    )
}