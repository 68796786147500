import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SchemaOf, object, string, number, date, boolean } from "yup";
import { Box, Button, Group, NumberInput, SegmentedControl, Select, Switch, Textarea, TextInput } from "@mantine/core";
import { CreateDeviceCommand, CreateDeviceCommandNew, useCreateDevice } from "src/features/devices/api/createDevice";
import SelectPositionMap from "src/components/Monitoring/SelectPositionMap";
import { useForm, yupResolver } from "@mantine/form";
import { debounce } from "lodash";
// import { useUsers } from "src/features/users/api/getUsers";
// import { useBindDevice } from "../api/bindDevice";
import { useAuth } from "../../../lib/auth";
// import { RoleEnum } from "../../auth";
import { DatePicker } from "@mantine/dates";
import { OverheadPowerLineTowersPhaseEnum } from "src/features/OPL/types";
import { SimpleSearchList } from "src/features/companies/components/OP";
import { useTowers } from "src/features/OPL/api/getTowers";
import CompaniesList from "src/features/companies/components/CompaniesList";
import { CompanyT } from "src/features/companies/api/getCompanies";
import { useCompany } from "src/features/companies/api/getCompany";
import { useDevice } from "../api/getDevice";
import { useUpdateDevice } from "../api/updateDevice";
import { useOPLs } from "src/features/OPL/api/getOPLs";
import { SearchingList } from "src/features/monitoring_towers/components/SearchingList";

// const CreateDeviceCommandSchema: SchemaOf<CreateDeviceCommandNew> = object({
//     Name: string().nullable(),
//     CCID: string().nullable(),
//     Comment: string().nullable(),
//     Latitude: number().nullable(),
//     Longitude: number().nullable(),
//     DeviceModelId: number().required(),
//     AutoTimeZoneDetection: boolean().required(),
//     AutoTimeZoneSeasonChanging: boolean().required(),
//     SummerTimeZoneChanging: date().nullable(),
//     WinterTimeZoneChanging: date().nullable(),
//     TimeZone: number().nullable().required(),

//     CompanyId: number().nullable(),
//     OverheadPowerLineTowerId: number().nullable(),
//     OverheadPowerLineTowersPhase: number().nullable()
// });

interface CreateDeviceDrawerProps {
    onComplete: () => void;
    company?: number;
    editing?: boolean;
    id?: number;
}

export const CreateDeviceDrawer: React.FC<CreateDeviceDrawerProps> = ({ onComplete, company, editing, id }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const createDeviceMutation = useCreateDevice({CompanyId: company});

    const updateDeviceMutation = useUpdateDevice({id: id})

    const { data: device } = useDevice(id)

    // const bindDeviceMutation = useBindDevice();
    // const { data: users } = useUsers();
    // const [userId, setUserId] = useState<number>(users ? users[0].Id : -1);

    const [coordinatesLocal, setCoordinatesLocal] = useState<[number | null | undefined, number | null | undefined]>([
        null,
        null,
    ]);

    const creatingInits = {
        Name: "",
        CCID: "",
        Comment: "",
        DeviceModelId: 1,
        Latitude: null,
        Longitude: null,
        AutoTimeZoneDetection: false,
        AutoTimeZoneSeasonChanging: false,
        SummerTimeZoneChanging: null,
        WinterTimeZoneChanging: null,
        TimeZone: 0,
        CompanyId: company ? company : user?.CompanyId ? user?.CompanyId : null,
        OverheadPowerLineTowerId: null,
        OverheadPowerLineTowersPhase: null,
    }

    const editingInits = {initialValues: {
        Name: device,
        CCID: "",
        Comment: "",
        DeviceModelId: 1,
        Latitude: null,
        Longitude: null,
        AutoTimeZoneDetection: false,
        AutoTimeZoneSeasonChanging: false,
        SummerTimeZoneChanging: null,
        WinterTimeZoneChanging: null,
        TimeZone: 0,
        CompanyId: company ? company : user?.CompanyId ? user?.CompanyId : null,
        OverheadPowerLineTowerId: null,
        OverheadPowerLineTowersPhase: OverheadPowerLineTowersPhaseEnum.A,
    },}

    const form = useForm<CreateDeviceCommandNew>({
        initialValues: creatingInits,
        // validate: yupResolver(CreateDeviceCommandSchema),
    });

    useEffect(()=>{
        if (editing && device)
            form.setValues({
                Name: device.Name,
                CCID: device.CCID,
                Comment: device.Comment,
                DeviceModelId: device.DeviceModel,
                Latitude: device.Latitude,
                Longitude: device.Longitude,
                AutoTimeZoneDetection: device.AutoTimeZoneDetection,
                AutoTimeZoneSeasonChanging: device.AutoTimeZoneSeasonChanging,
                SummerTimeZoneChanging: device.SummerTimeZoneChanging,
                WinterTimeZoneChanging: device.WinterTimeZoneChanging,
                TimeZone: device.TimeZone,
                CompanyId: device.CompanyId,
                OverheadPowerLineTowerId: device.OverheadPowerLineTowerId,
                OverheadPowerLineTowersPhase: device.OverheadPowerLineTowersPhase,
            })
    }, [device])

    // const [usersData, setUsersData] = useState<{label: string, value: string}[]>([])

    // useEffect(()=>{
    //     setUsersData(users?.map((x) => ({ label: x.UserName, value: String(x.Id) })) || [])
    // }, [users])

    function handleClose() {
        onComplete();
        form.reset();
    }

    const [companyFull, setCompany           ] = useState<CompanyT | null>(null     )
    const [path,        setPath              ] = useState<CompanyT[]     >([]     )
    const {data: userCompany} = useCompany(user?.CompanyId || null)
    useEffect(()=>{
        let tmp = JSON.parse(JSON.stringify(path))
        if(companyFull && path.length !== 0 && path[path.length-1] && path[path.length-1].Id === companyFull.CompanyParentId){
            tmp.push(companyFull)
            setPath(tmp)
        }
        else if(companyFull && path.length !== 0 && path[path.length-1] && path[path.length-1].CompanyParentId === companyFull.Id){
            tmp.pop()
            setPath(tmp)
        }
        else if(companyFull && path.length !== 0 && path[path.length-1] && path[path.length-1].CompanyParentId === companyFull.CompanyParentId){
            tmp.pop()
            tmp.push(companyFull)
            setPath(tmp)
        }
        else {
            tmp = []
            tmp.push(companyFull)
            setPath(tmp)
        }
        form.setFieldValue('CompanyId', companyFull?.Id)
    }, [companyFull])
    useEffect(()=>{
        setCompany(userCompany ? userCompany : null)
    }, [userCompany])

    const { data: towers } = useTowers()
    const { data: OPLs } = useOPLs()
    const [ selectedOPLid, setSelectedOPLid ] = useState<number | null>(null) 

    return (
        <form
            className="flex flex-col"
            onSubmit={form.onSubmit(
                !editing?
                    async (values) => {
                        const entityId = await createDeviceMutation.mutateAsync({
                            Name: values.Name,
                            CCID: values.CCID,
                            Comment: values.Comment,
                            DeviceModelId: values.DeviceModelId,
                            Latitude: values.Latitude ? values.Latitude : null,
                            Longitude: values.Longitude ? values.Longitude : null,
                            AutoTimeZoneDetection: values.AutoTimeZoneDetection,
                            AutoTimeZoneSeasonChanging: values.AutoTimeZoneSeasonChanging,
                            SummerTimeZoneChanging: values.SummerTimeZoneChanging ? values.SummerTimeZoneChanging : null,
                            WinterTimeZoneChanging: values.WinterTimeZoneChanging ? values.WinterTimeZoneChanging : null,
                            TimeZone: values.TimeZone,

                            CompanyId: company ? company : user?.CompanyId ? user?.CompanyId : null,
                            OverheadPowerLineTowerId: values.OverheadPowerLineTowerId,
                            OverheadPowerLineTowersPhase: values.OverheadPowerLineTowersPhase
                        });

                        // if (user?.Role !== RoleEnum.User && entityId.Id && userId) {
                        //     await bindDeviceMutation.mutateAsync({ UserId: userId, DeviceId: entityId.Id });
                        // }

                        handleClose();
                    }
                :
                    async (values) => {
                        const entityId = await updateDeviceMutation.mutateAsync({
                            Id: id ? id : 0,
                            Name: values.Name ? values.Name : '',
                            Comment: values.Comment ? values.Comment : '',
                            DeviceModelId: values.DeviceModelId,
                            Latitude: values.Latitude ? values.Latitude : null,
                            Longitude: values.Longitude ? values.Longitude : null,
                            AutoTimeZoneDetection: values.AutoTimeZoneDetection,
                            AutoTimeZoneSeasonChanging: values.AutoTimeZoneSeasonChanging,
                            SummerTimeZoneChanging: values.SummerTimeZoneChanging ? values.SummerTimeZoneChanging : null,
                            WinterTimeZoneChanging: values.WinterTimeZoneChanging ? values.WinterTimeZoneChanging : null,
                            TimeZone: values.TimeZone,

                            CompanyId: company ? company : user?.CompanyId ? user?.CompanyId : null,
                            OverheadPowerLineTowerId: values.OverheadPowerLineTowerId,
                            OverheadPowerLineTowersPhase: values.OverheadPowerLineTowersPhase
                        });

                        // if (user?.Role !== RoleEnum.User && entityId.Id && userId) {
                        //     await bindDeviceMutation.mutateAsync({ UserId: userId, DeviceId: entityId.Id });
                        // }

                        handleClose();
                    }
            )}
        >
            <div className="space-y-4 flex-auto">
                <TextInput
                    id="Name"
                    name="Name"
                    placeholder={t("Название")}
                    label={t("Название")}
                    {...form.getInputProps("Name")}
                />
                <TextInput
                    id="CCID"
                    name="CCID"
                    placeholder={t("CCID")}
                    label={t("CCID")}
                    {...form.getInputProps("CCID")}
                    // required
                />
                <Textarea
                    id="Comment"
                    name="Comment"
                    placeholder={t("Комментарий")}
                    label={t("Комментарий")}
                    {...form.getInputProps("Comment")}
                />
                {/* {user?.Role !== RoleEnum.User && (
                    <Select
                        data={usersData}
                        label={t("Привязка к пользователю")}
                        searchable={usersData.reduce((acc, x)=>acc=acc && x.label !== null)}
                        nothingFound="No options"
                        value={String(userId)}
                        onChange={(value) => setUserId(Number(value))}
                    />
                )} */}
                <div>
                <span className="mantine-InputWrapper-label mantine-NumberInput-label mantine-ittua2">{t("Привязка к компании")}</span>
                <CompaniesList
                    depth                   = {path.length} 
                    select_company          = {setCompany}
                    current_company         = {companyFull}
                    path                    = {path}
                    setPath                 = {setPath}
                    userCompany             = {userCompany?userCompany:null}
                    hideCollapser
                    fw
                    hideLabel
                    maxHeight               = {"300px"}
                    disableInputMargins
                />
                </div>
                <Select
                    value={form.values.DeviceModelId.toString()}
                    onChange={(value) => {
                        form.setFieldValue("DeviceModelId", Number(value));
                    }}
                    label={t("Тип устройства")}
                    data={[
                        { value: "1", label: t("МДД") },
                        { value: "2", label: t("КЗ") },
                        { value: "3", label: t("Универсальное") },
                        { value: "4", label: t("Универсальное 2.1") },
                        { value: "5", label: t("Универсальное 2.2") },
                    ]}
                />
                <Box
                    sx={{
                        height: "300px",
                    }}
                >
                    <SelectPositionMap
                        device={{
                            Id: 0,
                            CCID: form.values.CCID ? form.values.CCID : '',
                            Comment: form.values.Comment ? form.values.Comment : '',
                            Name: form.values.Name ? form.values.Name : '',
                            DeviceModel: form.values.DeviceModelId,
                            Latitude: coordinatesLocal[0],
                            Longitude: coordinatesLocal[1],
                            DeviceParameters: [],
                            DeviceChannels: [],
                            IceV2CalculationEnabled: false,
                            IceV3CalculationEnabled: false,
                            AutoTimeZoneDetection: form.values.AutoTimeZoneDetection,
                            AutoTimeZoneSeasonChanging: form.values.AutoTimeZoneSeasonChanging,
                            SummerTimeZoneChanging: form.values.SummerTimeZoneChanging,
                            WinterTimeZoneChanging: form.values.WinterTimeZoneChanging,
                            TimeZone: form.values.TimeZone ? form.values.TimeZone : null,
                            GetOscilloscope: false,
                            TimeSynchronization: false,
                            CompanyId: form.values.CompanyId ? form.values.CompanyId : null,
                            OverheadPowerLineTowerId: form.values.OverheadPowerLineTowerId ? form.values.OverheadPowerLineTowerId : null,
                            OverheadPowerLineTowersPhase: form.values.OverheadPowerLineTowersPhase ? form.values.OverheadPowerLineTowersPhase : null,
                        }}
                        onChange={debounce((value) => {
                            form.setFieldValue("Latitude", value[0]);
                            form.setFieldValue("Longitude", value[1]);
                        }, 300)}
                        onClick={(value) => {
                            setCoordinatesLocal(value);
                        }}
                    />
                </Box>
                <Group>
                    <NumberInput
                        placeholder={t("Широта")}
                        label={t("Широта")}
                        precision={4}
                        hideControls
                        {...form.getInputProps("Latitude")}
                    />
                    <NumberInput
                        placeholder={t("Долгота")}
                        label={t("Долгота")}
                        precision={4}
                        hideControls
                        {...form.getInputProps("Longitude")}
                    />
                </Group>
                <Switch
                    label={t("Автоматическое определение часового пояса")}
                    disabled={!form.values.Latitude || !form.values.Longitude}
                    {...form.getInputProps("AutoTimeZoneDetection", { type: "checkbox" })}
                />
                {!form.values.AutoTimeZoneDetection && (
                    <>
                        <NumberInput
                            label={t("Часовой пояс")}
                            step={1}
                            min={-11}
                            max={12}
                            {...form.getInputProps("TimeZone")}
                        />
                    </>
                )}
                <Switch
                    label={t("Изменение часового времени на летнее")}
                    {...form.getInputProps("AutoTimeZoneSeasonChanging", { type: "checkbox" })}
                />
                {form.values.AutoTimeZoneSeasonChanging && (
                    <>
                        <DatePicker
                            label={t("Дата перехода на летнее время")}
                            {...form.getInputProps("SummerTimeZoneChanging")}
                        ></DatePicker>
                        <DatePicker
                            label={t("Дата перехода на зимнее время")}
                            {...form.getInputProps("WinterTimeZoneChanging")}
                        ></DatePicker>
                    </>
                )}
                <div>
                    {t("Фаза")}
                    <SegmentedControl
                        className="mt-2"
                        fullWidth
                        value={form.values.OverheadPowerLineTowersPhase ? form.values.OverheadPowerLineTowersPhase.toString() : "undefined"}
                        onChange={event=>form.setFieldValue("OverheadPowerLineTowersPhase", parseInt(event) as OverheadPowerLineTowersPhaseEnum)}
                        data={[
                            {label: 'A', value: OverheadPowerLineTowersPhaseEnum.A.toString()},
                            {label: 'B', value: OverheadPowerLineTowersPhaseEnum.B.toString()},
                            {label: 'C', value: OverheadPowerLineTowersPhaseEnum.C.toString()},
                        ]}
                    />
                </div>
                <div>
                <span className="mantine-InputWrapper-label mantine-NumberInput-label mantine-ittua2">{t("Опора")}</span>
                {/* <SimpleSearchList
                    items={towers ? towers : []}
                    setValue={x => form.setFieldValue("OverheadPowerLineTowerId", x)}
                    chosenId={form.values.OverheadPowerLineTowerId ? form.values.OverheadPowerLineTowerId : null}
                /> */}
                <div style={{height: 300}}>
                    <SearchingList
                        levels
                        elements={[]}
                        id={null}
                        ids = {[selectedOPLid ? selectedOPLid : null, form.values.OverheadPowerLineTowerId ? form.values.OverheadPowerLineTowerId : null]}
                        setId = {null}
                        elements_lvl={[(OPLs ? OPLs : []), (towers ? towers.filter(x=>x.OverheadPowerLineId === selectedOPLid) : [])]}
                        setIds={[setSelectedOPLid, (id)=> {form.setFieldValue("OverheadPowerLineTowerId", towers &&  towers.filter(x=>x.Id === id)[0] ? towers.filter(x=>x.Id === id)[0].Id : null) }]}
                        styles={{width: "100%"}}
                        types={[t("ВЛ"), t("Опора")]}
                        errorLabel={form.values.OverheadPowerLineTowersPhase ? undefined : t("Привязка к опоре без указания фазы невозможна")}
                    />
                </div>
                </div>
            </div>
            <div className="space-x-3 mt-8 flex justify-end">
                <Button color="primary" variant="outline" onClick={handleClose}>
                    {t("Отмена")}
                </Button>
                <Button color="primary" type="submit">
                    {editing ? t("Сохранить") : t("Создать")}
                </Button>
            </div>
        </form>
    );
};
