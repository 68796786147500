import { ActionIcon, Group, Notification, Stack, Tooltip } from "@mantine/core"
import { NotificationsDto, NotificationTypeEnum } from "../types/index"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Icon123, IconChecks, IconTrash } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useReadNotification } from "../api/readNotification"
import { useTowersStore } from "src/features/OPL/store"
import { useNavigate } from "react-router"



// type UserNotificationsProps = {
//     type: NotificationTypeEnum,
//     target: {
//         OPL:    string,
//         Tower:  string,
//         Device: string,
//     },
//     // description: string,
//     date: string,
//     expanded?: boolean,
//     readen?: boolean,
// }

type UserNotificationProps = {
    notification: NotificationsDto,
    expanded?: boolean,
}

export const UserNotification: React.FC<UserNotificationProps> = ({notification, expanded}) => {

    const [title, setTitle] = useState<React.ReactNode>("")

    const {t} = useTranslation()

    const [ readen, setReaden ] = useState(false)

    const ReadNotificationMutation = useReadNotification(notification)

    const { selectTower } = useTowersStore()
    const navigate = useNavigate()
    

    useEffect(()=>{
        setReaden((notification.ReadDate && dayjs(notification.ReadDate || '').format("DD.MM.YY HH:mm").length !== 0) as boolean)
    }, [notification])

    useEffect(()=>{
        if ([
            NotificationTypeEnum.CriticalThreshold, 
            NotificationTypeEnum.InformationThreshold, 
            NotificationTypeEnum.WarningThreshold
        ].includes(notification.NotificationType))
            setTitle(
                <span>
                    {`${t("На")} `}
                    <Tooltip label={`${t("Перейти к")} ${notification.OverheadPowerLineName}/${notification.OverheadPowerLineTowerName}/${notification.DeviceName}`} withArrow>
                        <span 
                            style={{
                                fontWeight: "bold", 
                                cursor: "pointer"
                            }}
                            onClick={()=>{
                                selectTower({
                                    OverheadPowerLineId: notification.OverheadPowerLineId,
                                    Id: notification.OverheadPowerLineTowerId,
                                });
                                navigate("/monitoring")
                            }}
                        >
                        {`${notification.OverheadPowerLineName}/${notification.OverheadPowerLineTowerName}/${notification.DeviceName}`}
                        </span>
                    </Tooltip>
                    {` ${t("сработала")} ${
                        notification.NotificationType === NotificationTypeEnum.CriticalThreshold ? t("критическая")
                        : notification.NotificationType === NotificationTypeEnum.WarningThreshold ? t("предупредительная")
                        : notification.NotificationType === NotificationTypeEnum.InformationThreshold ? t("информационная")
                        : t("неизвестная")
                    } ${"уставка"}`}
                </span>
            )
        if (notification.NotificationType === NotificationTypeEnum.DeviceOffline)
            setTitle(
                <span>
                    {`${t("Устройство")} `}
                    <span style={{fontWeight: "bold"}}>
                    {`${notification.OverheadPowerLineName}/${notification.OverheadPowerLineTowerName}/${notification.DeviceName}`}
                    </span>
                    {`${" не в сети"}`}
                </span>
            )
    }, [notification.NotificationType])

    return(
        <Notification
            title = {title} 
            disallowClose
            style={{
                width: expanded ? "100%" : "540px",
                height: "fit-content",
                boxShadow: "none",
                backgroundColor: !readen ? notification.NotificationType === NotificationTypeEnum.CriticalThreshold ? "rgba(255, 0, 0, 0.04)"
                : notification.NotificationType === NotificationTypeEnum.WarningThreshold ? "rgba(255, 255, 0, 0.04)"
                : notification.NotificationType === NotificationTypeEnum.InformationThreshold ? "rgba(0, 255, 0, 0.04)"
                : "rgba(100, 100, 100, 0.04)" : "white"
            }}
            color={
                notification.NotificationType === NotificationTypeEnum.CriticalThreshold ? "red"
                : notification.NotificationType === NotificationTypeEnum.WarningThreshold ? "yellow"
                : notification.NotificationType === NotificationTypeEnum.InformationThreshold ? "green"
                : "gray"
            }
        >
            <Group align="space-between" style={{justifyContent: "space-between"}}>
                <Stack style={expanded ? {gap: "0px", flexGrow: 1, overflow: "hidden"} : {gap: "0px", width: "400px", overflow: "hidden"}}>
                    {/* <span style={{wordBreak: "break-all"}}>{description}</span> */}
                    <span>{dayjs(notification.Date || '').format("DD.MM.YY HH:mm")}</span>
                </Stack>
                <ActionIcon
                    onClick={() => {
                        ReadNotificationMutation.mutateAsync({
                            NotificationId: notification.Id,
                            CompanyId: notification.CompanyId,
                        })
                    }}
                    size="lg"
                    // variant="outline"

                >
                    <IconChecks/>
                </ActionIcon>
            </Group>
        </Notification>
    )
}