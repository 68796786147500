import { xor } from "lodash";
import create from "zustand";

import { Tower_full, Tower_small } from "../types";
import { useTowers } from "../api/getTowers";

export type TowersStore = {
    selectedOPLid?:         number | null,
    selectedTowerId?:       number | null;
    selectedChannelIds?:    number[];
    selectTower:            (tower: Tower_small | null | {OverheadPowerLineId?: number | null, Id?: number | null}) => void;
    selectOPL:              (OPLid: number | null) => void;
    unselectTower:          () => void;
    // toggleChannel: (deviceChannel: DeviceChannelDto) => void;
};

export const useTowersStore = create<TowersStore>((set) => ({
    selectTower: (tower: Tower_small | null | {OverheadPowerLineId?: number | null, Id?: number | null}) => {
        if (tower)
            set((state) => ({
                ...state,
                selectedOPLid:      tower.OverheadPowerLineId,
                selectedTowerId:    tower.Id,
                // selectedChannelIds: tower.DeviceChannels.map((x) => x.Id),
            }));
        else
            set((state) => ({
                ...state,
                selectedTowerId:    null,
                // selectedChannelIds: tower.DeviceChannels.map((x) => x.Id),
            }));
    },
    unselectTower: () => {
        set((state) => ({
            ...state,
            selectedOPLid:      null,
            selectedId:         null,
            // selectedChannelIds: undefined,
        }));
    },
    selectOPL: (id) => {
        set((state) => ({
            ...state,
            selectedOPLid:      id,
            selectedId:         null,
            // selectedChannelIds: undefined,
        }));
    }
    // toggleChannel: (deviceChannel: DeviceChannelDto) => {
    //     set((state) => ({
    //         ...state,
    //         selectedChannelIds: xor(state.selectedChannelIds || [], [deviceChannel.Id]),
    //     }));
    // },
}));
