import { Button, Center, Group, Modal, Pagination, SegmentedControl, Stack, Table } from "@mantine/core"
import { UserNotification } from "./Notification"
import { NotificationsDto, NotificationTypeEnum } from "../types"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useDisclosure } from "@mantine/hooks"
import { IconAlertCircle, IconAlertOctagon, IconCircle, IconFilter, IconInfoCircle, IconWifiOff } from "@tabler/icons-react"

// type ExpandedNotificationsProps = {
//     type: NotificationTypeEnum,
//     target: {
//         OPL:    string,
//         Tower:  string,
//         Device: string,
//     },
//     date: string,
//     readen?: boolean,
// }

type ExpandedNotificationsProps = {
    notification: NotificationsDto,
}

const NotificationExpanded:React.FC<ExpandedNotificationsProps> = ({notification}) => {
    return(
        <UserNotification
            notification={notification}
            expanded
        />
    )
} 

type UserCardProps = {
    // FIO: string, 
    Username: string, 
    // email: string, 
    date: string
}

const UserCard:React.FC<UserCardProps> = ({Username, date}) => {
    return (
        <Stack style={{backgroundColor: "white"}} align="center">
            <span className="font-bold text-gray-900">{Username}</span>
            {/* <Group className="text-gray-700">{Username} {email}</Group> */}
            <span>{date}</span>
        </Stack>
    )
}

type NotificationsPageProps = {
    notifications: NotificationsDto[],
}

export const NotificationsPage:React.FC<NotificationsPageProps> = ({notifications}) => {

    const { t } = useTranslation()

    const [activePage, setPage] = useState(1);

    const [stateFilter, setStateFilter] = useState("all")

    const [notificationsFiltered, setNotificationsFiltered] = useState<NotificationsDto[]>([])

    const [sortingOrder, setSortingOrder] = useState("newer")

    const [modalFiltersOpened, {open: openModalFilters, close: closeModalFilters}] = useDisclosure(false)

    const [typeFilter, setTypeFilter] = useState<NotificationTypeEnum | "all">("all")

    useEffect(()=>{
        setNotificationsFiltered(
            notifications.filter(notification => 
                (stateFilter === "all" ? true : stateFilter === "readen" ? (notification.ReadDate && dayjs(notification.ReadDate || '').format("DD.MM.YY HH:mm").length !== 0) as boolean : !!!notification.ReadDate)
                &&
                (typeFilter === "all" ? true : typeFilter === notification.NotificationType)
            )
            .sort((a,b)=>
                (sortingOrder === "newer") ? 
                    b.Id-a.Id
                : 
                    a.Id-b.Id
            )
        )
    }, [notifications, stateFilter, sortingOrder, typeFilter])

    useEffect(()=>{
        setPage(1)
    }, [stateFilter])

    return(
        <>
            <Modal
                opened={modalFiltersOpened}
                onClose={closeModalFilters}
                title={t("Фильтры")}
                size={"auto"}
            >
                <Stack>
                    <div className="space-y-2 flex-auto mb-6">
                        <span className="mb-2 text-base font-medium text-gray-900">
                            {t("Прочитанные")}
                        </span><br/>
                        <SegmentedControl
                            data={[
                                {value: "all", label: t("Все")},
                                {value: "readen", label: t("Прочитанные")},
                                {value: "unreaden", label: t("Непрочитанные")},
                            ]}
                            value={stateFilter}
                            onChange={setStateFilter}
                        />
                    </div>
                    <div className="space-y-2 flex-auto mb-6">
                        <span className="mb-2 text-base font-medium text-gray-900">
                            {t("Критичность")}
                        </span><br/>
                        <SegmentedControl
                            data={[
                                {value: "all", label: (
                                    <Center>
                                        <IconCircle color="black"/>
                                        <span className="ml-1">{t("Все")}</span>
                                    </Center>
                                )},
                                {value: NotificationTypeEnum.DeviceOffline.toString(), label: (
                                    <Center>
                                        <IconWifiOff color="gray"/>
                                        <span className="ml-1">{t("Не в сети")}</span>
                                    </Center>
                                )},
                                {value: NotificationTypeEnum.InformationThreshold.toString(), label: (
                                    <Center>
                                        <IconInfoCircle color="green"/>
                                        <span className="ml-1">{t("Информационные")}</span>
                                    </Center>
                                )},
                                {value: NotificationTypeEnum.WarningThreshold.toString(), label: (
                                    <Center>
                                        <IconAlertCircle color="rgb(200, 200, 0)"/>
                                        <span className="ml-1">{t("Предупредительные")}</span>
                                    </Center>
                                )},
                                {value: NotificationTypeEnum.CriticalThreshold.toString(), label: (
                                    <Center>
                                        <IconAlertOctagon color="red"/>
                                        <span className="ml-1">{t("Критические")}</span>
                                    </Center>
                                )},
                            ]}
                            value={typeFilter.toString()}
                            onChange={(event) => event === "all" ? setTypeFilter("all") : setTypeFilter(parseInt(event))}
                        />
                    </div>

                </Stack>
            </Modal>
            <Group position="apart">
                <SegmentedControl
                    data={[
                        {value: "newer", label: t("Сначала новые")},
                        {value: "older", label: t("Сначала старые")},
                    ]}
                    value={sortingOrder}
                    onChange={setSortingOrder}
                />
                <Button variant="outline" onClick={openModalFilters}>
                    {t("Фильтры")}
                    <IconFilter/>
                </Button>
            </Group>
            <Table>
                <thead>
                    <tr>
                        <th style={{textAlign: "center"}}>{t("Уведомление")}</th>
                        <th style={{textAlign: "center"}}>{t("Прочитавший пользователь")}</th>
                    </tr>
                </thead>
                <tbody>
                    {notificationsFiltered.slice(((activePage - 1) * 10), Math.min(notificationsFiltered.length, (activePage - 1) * 10 + 10)).map(notification => 
                        <tr>
                            <td style={{border: "none"}}>
                                <NotificationExpanded
                                    notification={notification}
                                />
                            </td>
                            <td style={{border: "none"}}>
                                {notification.ReadDate && dayjs(notification.ReadDate || '').format("DD.MM.YY HH:mm").length !== 0 ? 
                                <UserCard
                                    Username={notification.UserName || 'UnknownUser'}
                                    date={dayjs(notification.ReadDate || '').format("DD.MM.YY HH:mm")}
                                /> :
                                <Center>
                                    <span className="font-bold text-gray-900">{t("Уведомление не прочитано")}</span>
                                </Center>
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Pagination style={{justifyContent: "center", margin: "auto", marginTop: "10px", marginBottom: "10px"}} page={activePage} onChange={setPage} total={
                (Math.floor((notificationsFiltered.length || 0) / 10) + ((notificationsFiltered.length || 0) % 10 > 0 ? 1 : 0))
            } />
        </>
    )
}